import { action, observable } from 'mobx'
import RootStore from '../Root'
import { AudioProps } from '../types.d'
import { deleteRecordingInfo, getAudioInformation } from '../../api/vision/AudioActions'
import { initialTableDataWithPaginationDetails } from '../InitialValues'

interface AudioInfoData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<AudioProps>
}

class AudioStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public audioInformationData: AudioInfoData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean
    @observable public excludeTestAudios: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.excludeTestAudios = true
        this.searchItem = ''
        this.page = 1
        this.isPageOpened = false
        this.audioInformationData = initialTableDataWithPaginationDetails
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
        this.excludeTestAudios = true
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    //get the list of audio with details
    fetchAudioDetails = (page: number, searchItem: string, excludeTestAudios: boolean): void => {
        this.isLoading = true
        this.isPageOpened = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: page, search_item: searchItem.trim(), exclude_test_audios: excludeTestAudios }
        this.page = page
        this.searchItem = searchItem
        this.excludeTestAudios = excludeTestAudios

        getAudioInformation(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.audioInformationData = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    deleteRecording = (recording_id: string): void => {
        deleteRecordingInfo(recording_id).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchAudioDetails(this.page, this.searchItem, this.excludeTestAudios)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default AudioStore
