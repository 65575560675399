import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { FormateDate, isValueEquivalent } from '../commonComponent/commonFuction'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import { UpworkJobLoader } from '../commonComponent/DotLoader'
import Modal from '../commonComponent/Modal'
import ReactJson from 'react-json-view'
import { debounceSearch } from '../../utils/debounce'

interface Props {
    rootStore: RootStore,
}



const ThirdPartyLogPage: React.FC<Props> = ({ rootStore }) => {
    const { thirdPartyLogStore, authStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { fetchThirdPartyLog, thirdPartLogData, searchItem, isApiError, isLoading, apiErrorMessage, getRequestData, isLoadingInfo, requestData, responseData, getResponseData, isPageOpened, setSearchItemField, resetFilter } = thirdPartyLogStore
    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })

    const loadPage = (page: number) => {
        fetchThirdPartyLog(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchThirdPartyLog(1, e.target.value))
    }
    const openCloseModal = () => {
        setShow(!showModal)
    }
    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        getRequestData(data._id)
        openCloseModal()
    }

    const onTableResponseAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        getResponseData(data._id)
        openCloseModal()
    }

    const TableRequestDataDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}
        
        return (
            <>
                <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                    if (onTableAction != null) {
                        onTableAction('viewRequestData', value, data)
                    }
                }}>View Request Data</Link>
    
                {showModal && isValueEquivalent({ _id: data._id, action: 'viewRequestData' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.description}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
                        {isLoadingInfo ? <UpworkJobLoader /> : 
                            <>
                                <div><ReactJson src={requestData    } /></div>
                            </>
                        }
                    </Modal>) : null
                }
            </>
        )
    }

    const TableResponseDataDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}
        
        return (
            <>
                <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                    if (onTableResponseAction != null) {
                        onTableResponseAction('viewResponseData', value, data)
                    }
                }}>View Response Data</Link>
    
                {showModal && isValueEquivalent({ _id: data._id, action: 'viewResponseData' }, selectedRow) ? (
                    <Modal show={showModal}
                        modalTitle={data.description}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
                        {isLoadingInfo ? <UpworkJobLoader /> : 
                            <>
                                <div><ReactJson src={responseData} /></div>
                            </>
                        }
                    </Modal>) : null
                }
            </>
        )
    }

    useEffect(() => {
        setTitle('Third Party Logs | OathZephyr')
        if(isAdmin && !isPageOpened){
            resetFilter()
            fetchThirdPartyLog(1, '')
        }
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Third Party Logs
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {((isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{apiErrorMessage}</div>)}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[                                  
                                    { name: 'reference', title: 'Reference' },
                                    { name: 'https_method', title: 'Method' },
                                    { name: 'https_status', title: 'Status Code' },
                                    { name: 'url', class:'wrap-cell', title: 'URL' },
                                    { name: 'description', title: 'Description' },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: 'req', title: 'Request Data', component: TableRequestDataDetails },
                                    { name: 'resp', title: 'Response Data', component: TableResponseDataDetails }
                                ]}
                                data={thirdPartLogData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={thirdPartLogData.currentPage}
                            totalPages={thirdPartLogData.totalPage}
                            totalItems={thirdPartLogData.totalItem}
                            itemsPerPage={thirdPartLogData.itemPerPage}
                            isFiltered={thirdPartLogData.isFiltered}
                            totalAllItems={thirdPartLogData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ThirdPartyLogPage)
