import React from 'react'
import { Cell } from '../table/TableCellText.d'
import moment from 'moment'

export const TableCellStateName: React.FC<Cell> = (props) => {
    const value = props.value || ''
    const statesWithValueLabel = props.data.statesWithValueLabel
    const stateName = statesWithValueLabel.find(item => item.value === value)

    return (
        <>
            {stateName ? stateName.label : ''}
        </>
    )
}

export const FormateDateOnly: React.FC<Cell> = (props) => {
    const value = props.value || ''
    if (value === '') return value

    const date = moment(value).utc().format('MM/DD/YYYY')
    return <>{date}</>
}

export const FormateDateWithTime: React.FC<Cell> = (props) => {
    const date = props.value || ''
    if (date === '') return date

    const formattedDateTime = moment(date).utc().format('MM/DD/YYYY HH:mm:ss')
    return (
        <>
            {formattedDateTime}
        </>
    )
}

export const TableCellYesNo: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value === true ? 'Yes' : 'No' }
        </>
    )
}

export const TableCellNumberToString: React.FC<Cell> = (props) => {
    const value = props.value || 0

    return (
        <>{value.toString()}</>
    )
}