import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'
import uuid from 'react-uuid'
import { toast } from 'react-toastify'
import { debounceSearch } from '../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const TableCellTemplateNames: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value.length !== 0 ? props.value.map((item) => {
                return <div key={uuid()}>{item}</div>
            }) : '' }
        </>
    )
}

const TableCellTDeleteInfo: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            <Link className='text-danger' to='#' onClick={() => { (onActionClicked != undefined ) && onActionClicked('deleteInfo', value, data) }} title='Delete Info'>Delete</Link>
        </>
    )
}

const StateSpecificHCInfoPage: React.FC<Props> = ({ rootStore }) => {
    const { stateSpecificHCTempStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, page, isApiError, isLoading, apiErrorMessage, stateSpecificHCTempData, fetchStateSpecificHCTemps, getTemplateListForEmail, isPageOpened } = stateSpecificHCTempStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (newPage: number) => {
        fetchStateSpecificHCTemps(newPage, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchStateSpecificHCTemps(1, e.target.value))
    }

    const onTableAction = (action:string, value: any, data: any) => {
        const sure = confirm(`Are you sure you want to delete the ${data.state_id} record ?`)
        if (sure) {
            stateSpecificHCTempStore.delete(data._id, (flag: string) => {
                if (flag !== 'false') {
                    toast.success(flag)
                    fetchStateSpecificHCTemps(page, searchItem)
                } else {
                    toast.error('Sorry document not deleted please try again !')
                }
            })
        }
    }

    useEffect(() => {
        setTitle('State Specific HealthCare Templates | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchStateSpecificHCTemps(1, '')
            getTemplateListForEmail()
        }
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          State Specific Healthcare Template Info
                    <Link to='/state-specific-hc-info/add' className='ml-auto px-3'>Add State Specific Healthcare Info</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'state_id', title: 'State' },
                                    { name: 'template_id', title: 'Templates', component: TableCellTemplateNames },
                                    { name: '_id', title: 'Action', component: TableCellAction },
                                    { name: '_id', title: 'Delete', component: TableCellTDeleteInfo}
                                ]}
                                data={stateSpecificHCTempData.page_items}
                                onTableAction={onTableAction}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={stateSpecificHCTempData.currentPage}
                            totalPages={stateSpecificHCTempData.totalPage}
                            totalItems={stateSpecificHCTempData.totalItem}
                            itemsPerPage={stateSpecificHCTempData.itemPerPage}
                            isFiltered={stateSpecificHCTempData.isFiltered}
                            totalAllItems={stateSpecificHCTempData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(StateSpecificHCInfoPage)
