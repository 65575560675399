import { action, observable } from 'mobx'
import RootStore from './Root'
import { PackagePlanInfo, Dictionary } from './types.d'
import { initialTableDataWithPaginationDetails, initialPackagePlanData, } from './InitialValues'
import { getPackagePlanData, addUpdatePackagePlan, getPackagePlanDetails } from '../api/PackagePlanAction'

interface PackagePlanData {
    isFiltered: boolean
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<PackagePlanInfo>
}

class PackagePlanStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public packagePlanDetails: PackagePlanInfo
    @observable public packagePlanList: PackagePlanData
    @observable public searchItem: string
    @observable public page: number
    // @observable public isLoadingInfo: boolean
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.packagePlanDetails = initialPackagePlanData
        // this.isLoadingInfo = false
        this.packagePlanList = initialTableDataWithPaginationDetails
    }

    @action resetPackagePlanDetails = (): void => {
        this.packagePlanDetails = initialPackagePlanData
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    //get the list of plurals with details
    fetchPackagePlanData = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: searchItem }

        getPackagePlanData(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.packagePlanList = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //add or update package plan details using id
    addUpdatePackagePlan = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
    
        return new Promise((resolve, reject) => {
            addUpdatePackagePlan(data).then((response) => {
                this.isLoading = false
    
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchPackagePlanData(this.page, this.searchItem)
                resolve(response.data)
    
            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
    
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }


    //set the package plan details in packagePlanDetails variable for show in update package plan page
    setPackagePlanDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getPackagePlanDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.packagePlanDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default PackagePlanStore
