import { observable } from 'mobx'
import RootStore from './Root'
import { addUpdateStateSpecificHCTemp, getSingleStateSpecificHCTempDetails, getStateSpecificHCTemps, getAllTemplatesForEmail, deleteStateSpecificHCTempDetail } from '../api/StateSpecificHCTempAction'
import { StateSpecificHCTempInformation, Dictionary, NameId } from './types.d'
import { initialTableDataWithPaginationDetails, initialSingleStateSpecificHCTemp } from './InitialValues'

interface StateSpecificHCTempData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<StateSpecificHCTempInformation>
}

class StateSpecificHCTempStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public stateSpecificHCTempDetails: StateSpecificHCTempInformation
    @observable public stateSpecificHCTempData: StateSpecificHCTempData
    @observable public searchItem: string
    @observable public page: number
    @observable public templateListForEmail: Array<NameId>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.stateSpecificHCTempDetails = initialSingleStateSpecificHCTemp
        this.templateListForEmail = []
        this.stateSpecificHCTempData = initialTableDataWithPaginationDetails
    }

    getTemplateListForEmail = (): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAllTemplatesForEmail().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.templateListForEmail = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    resetStateSpecificHCTempDetails = (): void => {
        this.apiErrorMessage = ''
        this.isLoading = false
        this.isApiError = false
        this.stateSpecificHCTempDetails = {
            _id: '',
            state_id: '',
            template_id: ''
        }
    } 

    //get the list of StateSpecificHCTemps with details
    fetchStateSpecificHCTemps = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getStateSpecificHCTemps(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.stateSpecificHCTempData = response.data.data
            this.stateSpecificHCTempDetails = initialSingleStateSpecificHCTemp
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the details in stateSpecificHCTempDetails variable for show in update plural page
    setStateSpecificHCTempDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleStateSpecificHCTempDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.stateSpecificHCTempDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    setError = (apiErrorMessage: string) => {
        this.isApiError = true
        this.apiErrorMessage = apiErrorMessage
    }

    //update or delete the plural details of given plural_id
    addUpdateSingleStateSpecificHCTemp = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateStateSpecificHCTemp(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchStateSpecificHCTemps(this.page, this.searchItem)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    delete = (id: string, cb: (flag: string) => void): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        deleteStateSpecificHCTempDetail(id).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                cb('false')
            }
            cb(response.data.message)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
            cb('false')
        })
    }
}

export default StateSpecificHCTempStore
