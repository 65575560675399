import { action, observable } from 'mobx'
import RootStore from './Root'
import { MatterProps, SortConfig, ValueLabel } from './types.d'
import { getMatters, editCompletedFlagInMatter, editNeedInfoFlagInMatter } from '../api/MatterAction'
import { defaultAllObj, initialTableDataWithPaginationDetails } from './InitialValues'

interface MatterData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<MatterProps>
}

class MatterStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public matterData: MatterData
    @observable public searchItem: string
    @observable public searchType: ValueLabel
    @observable public filterWithNote: boolean
    @observable public filterWithFutureSigning: boolean
    @observable public filterWithNeededInfo: boolean
    @observable public filterWithExcludeMatterData: boolean
    @observable public page: number
    @observable public tableRows: Array<MatterProps>
    @observable public sortConfigForSigningDate: string
    @observable public sortKeyForUpdatedAt: string
    @observable public searchState: ValueLabel
    @observable public searchPackage: ValueLabel
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.searchType = defaultAllObj
        this.searchState = { value: 'all', label: 'All'}
        this.searchPackage = defaultAllObj
        this.filterWithNote = false
        this.filterWithFutureSigning = true
        this.filterWithNeededInfo = false
        this.filterWithExcludeMatterData = true
        this.page = 1
        this.matterData = initialTableDataWithPaginationDetails
        this.tableRows = []
        this.sortConfigForSigningDate = 'asc'
        this.sortKeyForUpdatedAt = 'asc'
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
        this.searchType = defaultAllObj
        this.searchState = { value: 'all', label: 'All'}
        this.searchPackage = defaultAllObj
        this.filterWithNote = false
        this.filterWithFutureSigning = true
        this.filterWithNeededInfo = false
        this.filterWithExcludeMatterData = true
        this.tableRows = []
        this.sortConfigForSigningDate = 'asc'
        this.sortKeyForUpdatedAt = 'asc'
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    fetchMatters = (page: number, searchItem: string, filterWithNote: boolean, filterWithFutureSigning: boolean, filterWithNeededInfo: boolean, searchType: ValueLabel, searchPackage: ValueLabel, searchState: ValueLabel, filterWithExcludeMatterData: boolean): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { 
            page: page, 
            search_item: searchItem.trim(), 
            filterWithNote, 
            is_future_signing: filterWithFutureSigning, 
            is_needed_info: filterWithNeededInfo, 
            is_exclude_test_matter: filterWithExcludeMatterData,
            searchType: searchType.value,
            searchState: searchState.value,
            searchPackage: searchPackage.value,
            sortConfigForSigningDate: this.sortConfigForSigningDate,
            sortKeyForUpdatedAt: this.sortKeyForUpdatedAt
        }
        this.page = page
        this.searchItem = searchItem
        this.filterWithNote = filterWithNote
        this.filterWithFutureSigning = filterWithFutureSigning
        this.filterWithNeededInfo = filterWithNeededInfo
        this.filterWithExcludeMatterData = filterWithExcludeMatterData
        this.searchType = searchType
        this.searchState = searchState
        this.searchPackage = searchPackage

        getMatters(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.matterData = response.data.data
            this.tableRows = response.data.data.page_items
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markCompleted(matterId: string, isCompleted: boolean) {
        const data = { _id: matterId, is_completed: isCompleted } 
        this.isLoading = true
        editCompletedFlagInMatter(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchMatters(this.page, this.searchItem, this.filterWithNote, this.filterWithFutureSigning, this.filterWithNeededInfo, this.searchType, this.searchPackage, this.searchState, this.filterWithExcludeMatterData)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markNeedInfo(matterId: string, needInfo: boolean) {
        const data = { _id: matterId, should_we_need_additional_info: needInfo } 
        this.isLoading = true
        editNeedInfoFlagInMatter(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchMatters(this.page, this.searchItem, this.filterWithNote, this.filterWithFutureSigning, this.filterWithNeededInfo, this.searchType, this.searchPackage, this.searchState, this.filterWithExcludeMatterData)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    sortMatterData = (sortConfig: SortConfig):void => {
        if(sortConfig.key === 'updatedAt'){
            this.sortKeyForUpdatedAt = sortConfig.direction
        } else if(sortConfig.key === 'signing_date'){
            this.sortConfigForSigningDate = sortConfig.direction
        }
        this.fetchMatters(this.page, this.searchItem, this.filterWithNote, this.filterWithFutureSigning, this.filterWithNeededInfo, this.searchType, this.searchPackage, this.searchState, this.filterWithExcludeMatterData)
    }
}

export default MatterStore
