import { action, observable } from 'mobx'
import RootStore from './Root'
import {
    getTemplates,
    getTemplateDetails,
    getHTMLDocumentDetail
} from '../api/TemplateActions'
import SingleTemplateStore from './SingleTemplateStore'
import { initialTableDataWithPaginationDetails, initialTemplate } from './InitialValues'
import { TemplatesList } from './types.d'


interface TemplateData {
  currentPage: number,
  totalPage: number,
  totalItem: number,
  itemPerPage: number,
  isFiltered: boolean,
  totalAllItems: number,
  page_items: Array<TemplatesList>
}

class TemplateStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public templateDetails: SingleTemplateStore
    @observable public templateData: TemplateData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean
    @observable public isHtmlDataLoading: boolean
    @observable public loaderCounter: number

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.templateDetails = new SingleTemplateStore(initialTemplate)
        this.templateData = initialTableDataWithPaginationDetails
        this.isHtmlDataLoading = false
        this.loaderCounter = 0
    }

    //get the list of Templates with details
    fetchTemplate = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        this.showLoader()
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getTemplates(requestData).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.templateData = response.data.data
            this.templateDetails = new SingleTemplateStore(initialTemplate)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    getOneTemplateDetails = (templateId: string, matterId: string) => {
        this.showLoader()
        this.isHtmlDataLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = {
            _id:templateId,
            matter_id: matterId
        }
        getTemplateDetails(requestData).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            } else {
                this.templateDetails = new SingleTemplateStore(response.data.data)
            }
            this.isHtmlDataLoading = false
        }).catch((error) => {
            console.log(error)
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    @action setPreviewDocumentInTemplateDetails = (): void => {
        this.showLoader()
        this.templateDetails = this.rootStore.clientStore.previewDocument
        this.hideLoader()
    }

    @action showLoader() {
        this.loaderCounter ++
        this.isLoading = true
    }

    @action hideLoader () {
        this.loaderCounter --
        if (this.loaderCounter <= 0) {
            this.isLoading = false
        }
    }

    updateTemplate = async (isDocument: boolean, matterId: string): Promise<void> => {
        this.showLoader()
        this.isApiError = false
        this.apiErrorMessage = ''
        await this.templateDetails.save(isDocument, matterId)
        this.hideLoader()
        this.apiErrorMessage = this.templateDetails.apiErrorMessage
        this.isApiError = this.templateDetails.isApiError

        if (this.apiErrorMessage === '401') {
            this.rootStore.authStore.setAuthToken('')
        }
    }

    getOneHTMLDocumentDetails = (matterId: string, documentId: string, forSpouse = false, UUID = '0', editedDocumentId: string): void => {
        this.showLoader()
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = {
            matter_id: matterId,
            document_id: documentId,
            for_spouse: forSpouse,
            UUID,
            _id: editedDocumentId
        }
        getHTMLDocumentDetail(requestData).then((response) => {
            this.hideLoader()
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
            } else {
                this.templateDetails = new SingleTemplateStore(response.data.data)
            }

        }).catch((error) => {
            console.log(error)
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.hideLoader()
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default TemplateStore
