import { action, observable } from 'mobx'
import RootStore from './Root'
import { getAppUsers, getAppUserDetails, updateAppUser } from '../api/AppUserActions'
import { initialAppUser, initialTableDataWithPaginationDetails } from './InitialValues'
import { AppUserInformation, ValueLabel } from './types.d'

interface AppUserData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<AppUserInformation>
}

interface Dictionary<T> {
    // eslint-disable-next-line
    [Key: string]: T | any
}

class AppUserStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public appUserDetails: AppUserInformation
    @observable public appUserData: AppUserData
    @observable public searchItem: string
    @observable public searchByRoleFilter: ValueLabel
    @observable public page: number
    @observable public isPageOpened: boolean
    @observable public excludeTestData: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.excludeTestData = true
        this.searchItem = ''
        this.searchByRoleFilter = { value: 'ALL', label: 'All' }
        this.page = 1
        this.appUserDetails = initialAppUser
        this.appUserData = initialTableDataWithPaginationDetails
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
        this.searchByRoleFilter = { value: 'ALL', label: 'All' }
        this.excludeTestData = true
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    resetAppUserDetails = ():void => {
        this.appUserDetails = initialAppUser
    }

    //get the list of App Users with details
    fetchAppUser = (page: number, searchItem: string, searchFromRole: ValueLabel, excludeTestData: boolean): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.searchByRoleFilter = searchFromRole
        this.excludeTestData = excludeTestData

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim(), search_from_role: this.searchByRoleFilter.value, exclude_test_data: this.excludeTestData}

        getAppUsers(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.resetAppUserDetails()
            this.appUserData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the app user details in appUserDetails variable for show in update App User page
    setAppUserDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAppUserDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.appUserDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update the appUser details of given appUser_id
    updateAppUserDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateAppUser(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }

                this.fetchAppUser(this.page, this.searchItem, this.searchByRoleFilter, this.excludeTestData)
                resolve(response.data)

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default AppUserStore
