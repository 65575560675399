import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Link } from 'react-router-dom'
import { TableCellAction } from '../../table/TableCellEditButton'
import { debounceSearch } from '../../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const ProblemQuestionsPage: React.FC<Props> = ({ rootStore }) => {
    const { problemQuestionStore, authStore, setTitle } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, problemQuestionData, fetchProblemQuestions, isPageOpened } = problemQuestionStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchProblemQuestions(page, searchItem, 'change_page')
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchProblemQuestions(1, e.target.value, 'search_item'))
    }

    useEffect(() => {
        setTitle('Vision - Questions | OathZephyr')
        if(isAdmin && !isPageOpened){
            fetchProblemQuestions(1, '', '')
        }
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Estate Planning Problem Questions
                    <Link to='/problem-questions/add' className='ml-auto px-3'>Add Problem Question</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'title', title: 'Title' },
                                    { name: 'display_order', title: 'Display Order' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={problemQuestionData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={problemQuestionData.currentPage}
                            totalPages={problemQuestionData.totalPage}
                            totalItems={problemQuestionData.totalItem}
                            itemsPerPage={problemQuestionData.itemPerPage}
                            isFiltered={problemQuestionData.isFiltered}
                            totalAllItems={problemQuestionData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(ProblemQuestionsPage)
