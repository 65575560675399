import React, { useState } from 'react'
import { NavLink} from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { version } from '../../../package.json'
import { observer } from 'mobx-react'
import { USER_ROLE_SUPER_ADMIN } from '../../utils/constant'
import FormModal from '../commonComponent/FormModal'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'

interface Props {
    rootStore: RootStore
}

const NavBar: React.FC<Props> = ({ rootStore }) => {
    const { authStore, allOrgStore, appUserStore, addressStore, clientStore, trusteeInfoStore, deedResearchStore, deedFilingStore, matterStore, clientDraftAdjustmentStore, probableStore, visionStore, invoiceInfoStore, planPricingTemplateStore, engagementTemplateStore, audioStore, errorLogStore, emailLogStore, thirdPartyLogStore, iPadLocalDataStore, lawmaticsStore } =  rootStore
    const { isUserLoggedIn, userRole } = authStore

    const [isOpenModel, setIsOpenModel] = useState(false)
    const toggle = () => {
        rootStore.navStore.collapse = !rootStore.navStore.collapse
    }

    const { handleSubmit, errors, control } = useForm()
    const openCloseModel = () => {
        setIsOpenModel(!isOpenModel)
    }

    const setOrganization = (data: any) => {
        if (data.organization.value !== authStore.userOrg.value) {
            appUserStore.setIsOpen(false)
            addressStore.setIsOpen(false)
            clientStore.setIsOpen(false)
            trusteeInfoStore.setIsOpen(false)
            deedResearchStore.setIsOpen(false)
            deedFilingStore.setIsOpen(false)
            clientDraftAdjustmentStore.setIsOpen(false)
            probableStore.setIsOpen(false)
            visionStore.setIsOpen(false)
            invoiceInfoStore.setIsOpen(false)
            planPricingTemplateStore.setIsOpen(false)
            engagementTemplateStore.setIsOpen(false)
            emailLogStore.setIsOpen(false)
            thirdPartyLogStore.setIsOpen(false)
            iPadLocalDataStore.setIsOpen(false)
            matterStore.setIsOpen(false)
            audioStore.setIsOpen(false)
            errorLogStore.setIsOpen(false)
            lawmaticsStore.setIsOpen(false)
    
            authStore.setUserOrganization({ _id: data.organization.value, name: data.organization.label}, true)
        }
        setIsOpenModel(!isOpenModel)
        
    }
    

    return <>
        <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow" style={{zIndex:101}}>
            <div className='col-md-3 col-lg-2 mr-0 px-3 d-flex'>
                <NavLink className="navbar-brand " to="/dashboard">{isUserLoggedIn && authStore.userOrg.label} <span className='version'>&nbsp;V.({version})</span></NavLink>
                <div className='dropdown-container' 
                >
                    <button type="button" onClick={openCloseModel} className={classNames('btn btn-light position-fixed mt-2', { 'd-none': userRole !== USER_ROLE_SUPER_ADMIN || !isUserLoggedIn })}>
                        Switch Organization
                    </button>
                </div>
            </div>
            
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" onClick={toggle}>
                <span className="navbar-toggler-icon"></span>
            </button>        

            <ul className="navbar-nav px-3 d-flex ">
                <li className={classNames('nav-item', { 'd-none': isUserLoggedIn })}>
                    <NavLink className="nav-link" to="login">Login</NavLink>
                </li>
            </ul>
        </nav>

        { isOpenModel && <FormModal show={isOpenModel} modalTitle='Select a Organization' openCloseModal={openCloseModel}>
            <form onSubmit={handleSubmit(setOrganization)} >
                <fieldset>
                    <div className='modal-body resource' >
                        <Controller
                            control={control}
                            name="organization"
                            defaultValue={authStore.userOrg}
                            render={({ onChange, ref, value }) => (
                                <Select
                                    isSearchable
                                    inputRef={ref}
                                    value={value}
                                    options={allOrgStore.orgListValueLabel}
                                    className={(errors.organization) ? 'is-invalid' : ''}
                                    classNamePrefix="select"
                                    onChange={val => {
                                        onChange(val)
                                    }}
                                />)}
                            rules={{
                                validate: (value) => (value === undefined) ? 'Please select organization' : true
                            }}
                        />
                        {(errors.organization) && (<div className='invalid-feedback'>{errors.organization.message}</div>)}
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModel}>Cancel</button>
                        <button type="submit" className="btn btn-outline-primary mr-3">Update</button>
                    </div>
                </fieldset>
            </form>
        </FormModal>
      
           

        }   
    </>
}

export default observer(NavBar)
