import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdatePackagePlan = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/package-plans/add-update`
    return axios.post(url, requestData)
}

export const getPackagePlanData = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/package-plans/`
    return axios.post(url, requestData)
}

export const getPackagePlanDetails = (packageId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/package-plans/package-plan/${packageId}`
    return axios.post(url)
}