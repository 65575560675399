import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellNumberToString } from '../commonComponent/TableCell'
import { debounceSearch } from '../../utils/debounce'
import { Cell } from '../table/TableCellText.d'
import { isValueEquivalent } from '../commonComponent/commonFuction'
import Modal from '../commonComponent/Modal'
import { Link } from 'react-router-dom'
import { FormateDate } from '../commonComponent/commonFuction'

interface Props {
  rootStore: RootStore,
}

const IpadLocalData: React.FC<Props> = ({ rootStore }) => {
    const { iPadLocalDataStore, setTitle, authStore } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, iPadLocalData, fetchIpadLocalData, isPageOpened, getIPadLocalData, isLoadingInfo, ipadLocalDataDetails, resetFilter, setSearchItemField } = iPadLocalDataStore
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    const [showModal, setShow] = useState(false)

    const loadPage = (page: number) => {
        fetchIpadLocalData(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchIpadLocalData(1, e.target.value))
    }

    useEffect(() => {
        setTitle('iPad Local Data | OathZephyr')
        if(isAdmin && !isPageOpened) {
            resetFilter()
            fetchIpadLocalData(1, '')
        }
    }, [fetchIpadLocalData, isAdmin, setTitle, userOrg])

    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        getIPadLocalData(data._id)
        openCloseModal()
    }

    const openCloseModal = () => {
        setShow(!showModal)
    }

    const TableFetchedDataDetails: React.FC<Cell> = (props) => {
        const value = props.data._id || ''
        const data = props.data || {}
    
        return (
            <>
                <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                    if (onTableAction != null) {
                        onTableAction('viewiPadLocalData', value, data)
                    }
                }}>View iPad Local Data</Link>
    
                {showModal && isValueEquivalent({ _id: data._id, action: 'viewiPadLocalData' }, selectedRow) && (
                    <Modal show={showModal}
                        modalTitle={data.user_id}
                        openCloseModal={openCloseModal}
                        modalDialogClass='modal-lg'
                        cancelButtonName='Close'
                        isScrollable={true}
                        cancelButton={true}
                        isSaveButton={false}
                    >
                        <Table 
                            isLoading={isLoadingInfo}
                            unique_key='_id'
                            columns={[
                                { name: 'collection_name', title: 'Collection Name' },
                                { name: 'sync_data_count', title: 'Sync data count', component: TableCellNumberToString },
                                { name: 'un_sync_data_count', title: 'Unsync data count', component: TableCellNumberToString }
                            ]}
                            data={ipadLocalDataDetails.fetched_records}
                        />      
                    </Modal>)
                }
            </>
        )
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    iPad Local Data
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'user_id', title: 'iPad User Name' },
                                    { name: 'type', title: 'Types' },
                                    { name: 'app_version', title: 'App Version' },
                                    { name: 'device_udid', title: 'Device UDID' },
                                    { name: 'device_name', title: 'Device Name' },
                                    { name: 'device_os', title: 'Device OS' },
                                    { name: 'device_time', title: 'Device Time' },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: 'updatedAt', title: 'Updated At', component: FormateDate },
                                    { name: 'fetched_records', title: 'Fetched Records', component: TableFetchedDataDetails },
                                ]}
                                data={iPadLocalData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={iPadLocalData.currentPage}
                            totalPages={iPadLocalData.totalPage}
                            totalItems={iPadLocalData.totalItem}
                            itemsPerPage={iPadLocalData.itemPerPage}
                            isFiltered={iPadLocalData.isFiltered}
                            totalAllItems={iPadLocalData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(IpadLocalData)
