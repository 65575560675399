import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'
import Select from 'react-select'
import { toast } from 'react-toastify'


const AddUpdatePackagePlanPage: React.FC<Props> = ({ rootStore }) => {
    const { handleSubmit, errors, control, setValue } = useForm()
    const { setTitle, packagePlanStore, allPackagesStore, planStore } = rootStore
    const { planNameId } = planStore
    const { isApiError, isLoading, apiErrorMessage, setPackagePlanDetails, addUpdatePackagePlan, resetPackagePlanDetails, packagePlanDetails } = packagePlanStore
    const { packagesWithValueLabel } = allPackagesStore

    let isUpdateMode = false
    const history = useHistory()
    const { packagePlanId } = useParams<{ packagePlanId: string}>()

    if (packagePlanId) {
        isUpdateMode = true

    }

    useEffect(() => {
        if (packagePlanId) {
            setPackagePlanDetails(packagePlanId)
            if (packagePlanDetails._id !== '') {
                setValue('package_id', { value: packagePlanDetails.package_id, label: packagePlanDetails.package_name })
                setValue('plan_id', { value: packagePlanDetails.plan_id, label: packagePlanDetails.plan_name })
            }

        } else if(packagePlanId === undefined) {
            resetPackagePlanDetails()
        }
        allPackagesStore.getAllPackageNames()
        planStore.fetchPlanNameId()
    }, [packagePlanId, resetPackagePlanDetails, allPackagesStore, setValue, planStore, setPackagePlanDetails, packagePlanDetails._id, packagePlanDetails.plan_id, packagePlanDetails.package_id, packagePlanDetails.plan_name, packagePlanDetails.package_name])

    useEffect(() => {
        if (isUpdateMode) {
            if(packagePlanId === packagePlanDetails._id){
                setTitle(`${packagePlanDetails.package_name} | Update Package Plan`)
            }
        } else {
            setTitle('Package Plan | Add')
        }
    })

    const handleClick = () => {
        resetPackagePlanDetails()
        history.push('/package-plans/')
    }
    
    const onSubmit = async (data: Dictionary<string>) => {
        console.log(data, 'data')
        const requestData = {
            _id: isUpdateMode ? packagePlanId : '',
            plan_id: data.plan_id.value,
            plan_name: data.plan_id.label,
            package_id: data.package_id.value,
            package_name: data.package_id.label,
        }
        console.log(requestData)
        const response = await addUpdatePackagePlan(requestData)
        if (response.success === 1) {
            history.push('/package-plans/')
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Package Plans', to: '/package-plans/' },
            { name: (isUpdateMode) ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {(isUpdateMode) ? 'Update' : 'Add'} Package Plan
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>
                                <div className='form-group'>
                                    <label>Plan<span style={{color:'red'}}>*</span></label>
                                    <Controller
                                        control={control}
                                        name="plan_id"
                                        rules={{
                                            validate: (value) => (value === undefined || value === null) ? 'Please select Plan' : true
                                        }}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                inputRef={ref}
                                                value={value}
                                                options={planNameId}
                                                className={(errors.plan_id) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                                placeholder='Select the Plan'
                                                isSearchable
                                                menuPortalTarget={document.body}
                                            />)}
                                            
                                    />
                                    {(errors.plan_id) && (<div className='invalid-feedback'>Please select Plan</div>)}
                                </div>

                                <div className='form-group'>
                                    <label>Package<span style={{color:'red'}}>*</span></label>
                                    <Controller
                                        control={control}
                                        name="package_id"
                                        className='form-group'
                                        rules={{
                                            validate: (value) => (value === undefined || value === null) ? 'Please select Package' : true
                                        }}
                                        render={({ onChange, ref, value }) => (
                                            <Select
                                                inputRef={ref}
                                                value={value}
                                                options={packagesWithValueLabel}
                                                className={(errors.package_id) ? 'is-invalid' : ''}
                                                classNamePrefix="select"
                                                onChange={val => {
                                                    onChange(val)
                                                }}
                                                placeholder='Select the Package'
                                                isSearchable
                                                menuPortalTarget={document.body}
                                            />)}
                                    />
                                    {(errors.package_id) && (<div className='invalid-feedback'>Please select Package</div>)}
                                </div>

                                <div className='form-group mt-4'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePackagePlanPage)
