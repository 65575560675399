import React, { useEffect } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Props } from '../../store/types.d'
import { debounceSearch } from '../../utils/debounce'
import { Link } from 'react-router-dom'
import { TableCellAction } from '../table/TableCellEditButton'


const PackagePlan: React.FC<Props> = ({ rootStore }) => {
    const { packagePlanStore, authStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, packagePlanList, fetchPackagePlanData, isPageOpened, resetFilter, setSearchItemField } = packagePlanStore

    const loadPage = (page: number) => {
        fetchPackagePlanData(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchPackagePlanData(1, e.target.value.trim()))
    }

    useEffect(() => {
        if(isAdmin && !isPageOpened) {
            resetFilter()
            fetchPackagePlanData(1, '')
        }
    }, [userOrg])

    useEffect(() => {
        setTitle('Package Plan | OathZephyr')
    }, [setTitle])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Package Plans
                    <Link to='/package-plans/add' className='ml-auto px-3'>Add Package Plan</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'plan_name', title: 'Plan Name' },
                                    { name: 'package_name', title: 'Package Name' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={packagePlanList.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={packagePlanList.currentPage}
                            totalPages={packagePlanList.totalPage}
                            totalItems={packagePlanList.totalItem}
                            itemsPerPage={packagePlanList.itemPerPage}
                            isFiltered={packagePlanList.isFiltered}
                            totalAllItems={packagePlanList.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(PackagePlan)
