import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Link } from 'react-router-dom'
import { TableCellAction } from '../../table/TableCellEditButton'
import { debounceSearch } from '../../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const PlanPricingTemplatePage: React.FC<Props> = ({ rootStore }) => {
    const { planPricingTemplateStore, authStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, fetchPlanPricingTemplates, planPricingData, isPageOpened, resetFilter, setSearchItemField } = planPricingTemplateStore

    const loadPage = (page: number) => {
        fetchPlanPricingTemplates(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchPlanPricingTemplates(1, e.target.value))
    }

    useEffect(() => {
        setTitle('Vision - Plan Pricing Template | OathZephyr')
        if (isAdmin && !isPageOpened) {
            resetFilter()
            fetchPlanPricingTemplates(1, '')
        }
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Plan Pricing Templates
                    <Link to='/plan-pricing-templates/add' className='ml-auto px-3'>Add Plan Pricing Template</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'createdAt', title: 'CreatedAt' },
                                    { name: 'updatedAt', title: 'UpdatedAt' },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={planPricingData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={planPricingData.currentPage}
                            totalPages={planPricingData.totalPage}
                            totalItems={planPricingData.totalItem}
                            itemsPerPage={planPricingData.itemPerPage}
                            isFiltered={planPricingData.isFiltered}
                            totalAllItems={planPricingData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(PlanPricingTemplatePage)
