import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Link } from 'react-router-dom'
import { Cell } from '../../table/TableCellText.d'
import { debounceSearch } from '../../../utils/debounce'

interface Props {
  rootStore: RootStore,
}

const ConvertBooleanToString: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return <>{value === true ? 'Yes' : 'No' }</>
}

const EngagementTemplatesPage: React.FC<Props> = ({ rootStore }) => {
    const { engagementTemplateStore, authStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, engagementTemplateData, fetchEngagementTemplates, setEngagementTemplateDetails, isPageOpened, resetFilter, setSearchItemField } = engagementTemplateStore

    const loadPage = (page: number) => {
        fetchEngagementTemplates(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchEngagementTemplates(1, e.target.value))
    }

    const TableCellNewTemplate: React.FC<Cell> = (props) => {
        let value = props.value || ''
        // Convert boolean values to string so that they are visible in table
        if (value === true || value === false) {
            value = value.toString()
        }

        const setEngagementTemplate = () => {
            setEngagementTemplateDetails(value)
        }
    
        return (
            <>
                <Link to={`/engagement-templates/update/${value}`} className='mr-2'>Update</Link>
                <Link to='/engagement-templates/add' title='Create New Template using Content of this Template' onClick={setEngagementTemplate} >Create Duplicate Template</Link>
            </>
        )
    }

    useEffect(() => {
        setTitle('Vision - Engagement Templates | OathZephyr')
        if(isAdmin && !isPageOpened){
            resetFilter()
            fetchEngagementTemplates(1, '')
        }
    }, [userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
          Engagement Templates
                    <Link to='/engagement-templates/add' className='ml-auto px-3'>Add Engagement Template</Link>
                </div>
            </div>
            
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' value={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'is_default', title: 'Default', component: ConvertBooleanToString },
                                    { name: '_id', title: 'Action', component: TableCellNewTemplate }
                                ]}
                                data={engagementTemplateData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={engagementTemplateData.currentPage}
                            totalPages={engagementTemplateData.totalPage}
                            totalItems={engagementTemplateData.totalItem}
                            itemsPerPage={engagementTemplateData.itemPerPage}
                            isFiltered={engagementTemplateData.isFiltered}
                            totalAllItems={engagementTemplateData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(EngagementTemplatesPage)
