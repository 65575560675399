import React, { useEffect, useRef, useState } from 'react'
import RootStore from '../../store/Root'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Container from '../layout/Container'
import { useHistory, useParams } from 'react-router-dom'
import { handleKeyDownWithOutSpace } from '../commonComponent/WithoutAcceptSpace'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { ValueLabel } from '../../store/types.d'
import { initialValueLabel } from '../../store/InitialValues'
import { makeValueLabelList } from '../commonComponent/commonFuction'
import { USER_ROLE_DEED_ONLY, USER_ROLE_ORG_ADMIN } from '../../utils/constant'
import { toast } from 'react-toastify'
import Select from 'react-select'


interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const UpdateAppUserPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors, watch, setValue, control } = useForm()
    const { appUserStore, allPlanPricingTemplateStore, allEngagementTemplateStore, allTagStore, setTitle, allAddressStore, allStatesStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, appUserDetails, setAppUserDetails, updateAppUserDetails, resetAppUserDetails } = appUserStore
    const { statesWithValueLabel } = allStatesStore

    const { planPricingTemplatesWithValueLabel } = allPlanPricingTemplateStore
    const [planPricingTemplate, setPlanPricingTemplate] = useState<ValueLabel>(initialValueLabel)

    const { engagementTemplatesWithValueLabel } = allEngagementTemplateStore
    const [engagementTemplate, setEngagementTemplate] = useState<ValueLabel>(initialValueLabel)

    const { tagList } = allTagStore
    const { addressWithValueLabel } = allAddressStore
    const [selectedOptionOfTag, setSelectedOptionOfTag] = useState<Array<ValueLabel>>([])
    const [selectedOptionOfAddress, setSelectedOptionOfAddress] = useState({ value: '', label: 'None' })
    const [recordingStatus, setRecordingStatus] = useState(true)

    const password = useRef({})
    password.current = watch('password', '')
    const passwordLength = 16
    const history = useHistory()

    const currentUserRole = localStorage.getItem('userRole') || ''

    const { userId } = useParams<{ userId:string }>()
    const appUserId = userId
    const option = tagList.map((list: any) => {
        const data = {
            value: list.tag || '',
            label: list.tag || ''
        }
        return data
    })

    useEffect(() => {
        if (appUserDetails._id === '' || appUserId !== appUserDetails._id) {
            setAppUserDetails(appUserId)
        }

        if (appUserDetails._id !== '') {
            const selectedPlanPricing = planPricingTemplatesWithValueLabel.find(item => item.value === appUserDetails.user_specific_price_template_id)

            if (selectedPlanPricing !== undefined) {
                setPlanPricingTemplate({ value: selectedPlanPricing.value, label: selectedPlanPricing.label })
            } else {
                setPlanPricingTemplate(initialValueLabel)
            }

            const selectedEngagement = engagementTemplatesWithValueLabel.find(item => item.value === appUserDetails.engagement_template_id)

            if (selectedEngagement !== undefined) {
                setEngagementTemplate({ value: selectedEngagement.value, label: selectedEngagement.label })
            } else {
                setEngagementTemplate(initialValueLabel)
            }

            const selectedAddress = addressWithValueLabel.find(item => item.value === appUserDetails.address_id)

            if (selectedAddress !== undefined) {
                setSelectedOptionOfAddress({ value: selectedAddress.value, label: selectedAddress.label })
            } else {
                setSelectedOptionOfAddress(initialValueLabel)
            }

            setRecordingStatus(appUserDetails.default_vision_recording_status === undefined ? true : appUserDetails.default_vision_recording_status)
            const selected = makeValueLabelList(appUserDetails.user_associated_tags, option)
            if (selected !== undefined) {
                setSelectedOptionOfTag(selected)
            } else {
                setSelectedOptionOfTag([])
            }    
            
            const selectedState = statesWithValueLabel.find(item => item.value === appUserDetails.default_state_id)
            if (selectedState !== undefined) {
                setValue('default_state_id', { value: selectedState.value, label: selectedState.label })

            }
        }
    }, [setAppUserDetails, appUserId, setPlanPricingTemplate, planPricingTemplatesWithValueLabel, setEngagementTemplate, engagementTemplatesWithValueLabel, appUserDetails, setSelectedOptionOfAddress])

    const handleClick = () => {
        history.push('/app-users/')
    }

    const handleOnPlanPricingTemplate = (data: any) => {
        if (data !== null) {
            setPlanPricingTemplate(data)
        }
    }
    const handleOnEngagementTemplate = (data: any) => {
        if (data !== null) {
            setEngagementTemplate(data)
        }
    }

    const handleOnFieldTagChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfTag(data)
        }
    }

    const handleVisionRecordingStatus = (e: any) => {
        const value = e.target.checked
        setRecordingStatus(value)
    }

    const handleOnFieldAddressChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfAddress(data)
        }
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const tags = selectedOptionOfTag?.map(tag => tag.value)
        console.log('api called', data)
        const requestData = {
            app_user_id: appUserId,
            first_name: data.first_name,
            last_name: data.last_name,
            password: data.password,
            status: data.status,
            role: (appUserDetails.role === USER_ROLE_ORG_ADMIN) ? USER_ROLE_ORG_ADMIN : data.role,
            account_code: data.account_code,
            attorney_code: data.attorney_code,
            user_specific_price_template_id: planPricingTemplate.value,
            engagement_template_id: engagementTemplate.value,
            user_associated_tags: tags,
            default_vision_recording_status: recordingStatus,
            address_id: selectedOptionOfAddress.value,
            default_state_id: data.default_state_id ? data.default_state_id.value : '',
            default_county: data.default_county || ''
        }

        const response = await updateAppUserDetails(requestData)
        if (response.success === 1) {
            resetAppUserDetails()
            history.push('/app-users/')
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        }
    }

    useEffect(() => {
        setTitle(`User | ${appUserDetails.first_name}`)
    },[appUserDetails])

    useEffect(() => {
        allTagStore.getAllTags()
        allEngagementTemplateStore.getAllEngagementTemplateNameIDs()
        allPlanPricingTemplateStore.getAllPlanPricingTemplateNameIDs()
        allAddressStore.getAllAddressNames()
        allStatesStore.getAllStates()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Users', to: '/app-users/' },
            { name: 'Update', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
            Update User
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(appUserId === appUserDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <div className={classNames('form-group', { 'd-none': appUserDetails.role === USER_ROLE_ORG_ADMIN })}>
                                        <label>Role</label>
                                        <div className='radio' >
                                            <label className='mr-2'><input name='role' type='radio' value='EMPLOYEE' defaultChecked={appUserDetails.role === 'EMPLOYEE'} ref={register} /> Team-iPad</label>
                                            <label><input name='role' type='radio' defaultChecked={appUserDetails.role === 'ADMIN'} value='ADMIN' ref={register} />  Admin-iPad</label>
                                            <label><input className='ml-2' name='role' type='radio' defaultChecked={appUserDetails.role === 'ADMIN_CMS'} value='ADMIN_CMS' ref={register} /> Team-CMS</label>
                                            <label><input className='ml-2' name='role' type='radio' defaultChecked={appUserDetails.role === 'TEAM_CMS_IPAD'} value='TEAM_CMS_IPAD' ref={register} /> Team-iPad/CMS</label>
                                            <label><input className='ml-2' name='role' type='radio' defaultChecked={appUserDetails.role === USER_ROLE_DEED_ONLY} value={USER_ROLE_DEED_ONLY} ref={register} /> Deed Only</label>
                                            { currentUserRole !== USER_ROLE_ORG_ADMIN && <label><input className='ml-2' name='role' type='radio' defaultChecked={appUserDetails.role === 'ADMIN_CMS_TEAM_IPAD'} value='ADMIN_CMS_TEAM_IPAD' ref={register} /> Admin-CMS/Team-iPad</label> }
                                            {/* { currentUserRole !== USER_ROLE_ORG_ADMIN && <label><input className='ml-2' name='role' type='radio' defaultChecked={appUserDetails.role === USER_ROLE_ORG_ADMIN} value={USER_ROLE_ORG_ADMIN} ref={register} /> Organization Admin</label>} */}
                                        </div>
                                        {errors.role && (<div className='invalid-feedback'>{errors.role.message}</div>)}
                                    </div>

                                    <InputField
                                        label='First Name *'
                                        fieldName='first_name'
                                        register={register}
                                        errors={errors.first_name}
                                        placeholder='Enter First Name'
                                        defaultValue={appUserDetails.first_name}
                                        required={true}
                                    />
                                    <InputField
                                        label='Last Name *'
                                        fieldName='last_name'
                                        register={register}
                                        errors={errors.last_name}
                                        placeholder='Enter Last Name'
                                        defaultValue={appUserDetails.last_name}
                                        required={true}
                                    />

                                    <div className='form-group'>
                                        <label>Office Address</label>
                                        <ReactSelectComponent
                                            options={addressWithValueLabel}
                                            value={selectedOptionOfAddress}
                                            handleOnChangeReact={handleOnFieldAddressChange}
                                        />
                                    </div>
                                
                                    <div className='form-group'>
                                        <label>User Associated Tags</label>
                                        <ReactSelectComponent
                                            options={option}
                                            isMulti={true}
                                            value={selectedOptionOfTag}
                                            handleOnChangeReact={handleOnFieldTagChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            ref={register}
                                            name='email'
                                            type='text'
                                            value={appUserDetails.email}
                                            className={classNames('form-control', { 'is-invalid': errors.email })}
                                            disabled
                                        />
                                        {errors.email && (<div className='invalid-feedback'>{errors.email.message}</div>)}
                                    </div>

                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            ref={register({
                                                pattern: {
                                                    value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
                                                    message: 'Password must be at least 8 and at most 16 character long and should have one uppercase alphabet, one lowercase alphabet, one number and one special character'
                                                }
                                            })}
                                            maxLength={passwordLength}
                                            name='password'
                                            type='password'
                                            autoComplete='new-password'
                                            onKeyDown={handleKeyDownWithOutSpace}
                                            className={classNames('form-control', { 'is-invalid': errors.password })}
                                            placeholder='Enter Password'
                                        />
                                        {errors.password ? (<div className='invalid-feedback'>{errors.password.message}</div>) : (<small className='form-text text-muted'>Your password must be 8-11 characters long, should have one uppercase alphabet, one lowercase alphabet, one number and one special character.</small>)}
                                    </div>

                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input
                                            ref={register({
                                                validate: value =>
                                                    value === password.current || 'Confirm password does not match'
                                            })}
                                            name='confirm_password'
                                            type='password'
                                            maxLength={passwordLength}
                                            onKeyDown={handleKeyDownWithOutSpace}
                                            className={classNames('form-control', { 'is-invalid': errors.confirm_password })}
                                            placeholder='Enter Confirm Password'
                                        />
                                        {errors.confirm_password && (<div className='invalid-feedback'>{errors.confirm_password.message}</div>)}
                                    </div>

                                    <InputField
                                        label='Xero Account Code'
                                        fieldName='account_code'
                                        register={register}
                                        errors={errors.account_code}
                                        placeholder='Enter Xero Account Code'
                                        defaultValue={appUserDetails.account_code}
                                    />

                                    <InputField
                                        label='Xero Attorneys'
                                        fieldName='attorney_code'
                                        register={register}
                                        errors={errors.attorney_code}
                                        placeholder='Enter Xero Attorneys'
                                        defaultValue={appUserDetails.attorney_code}
                                    />
                                    <div className='form-group'>
                                        <label>Active Status</label>
                                        <div className='radio' >
                                            <label> <input name='status' type='radio' defaultChecked={appUserDetails.status === 'ACTIVE'} value='ACTIVE' ref={register} /> Active</label> &nbsp;&nbsp;
                                            <label> <input name='status' type='radio' defaultChecked={appUserDetails.status === 'INACTIVE'} value='INACTIVE' ref={register} /> Inactive</label>
                                        </div>
                                        {errors.status && (<div className='invalid-feedback'>{errors.status.message}</div>)}
                                    </div>
                                    <div className='form-group'>
                                        <label>Plan Pricing Template</label>
                                        <ReactSelectComponent
                                            placeholder='Select...'
                                            options={planPricingTemplatesWithValueLabel}
                                            value={planPricingTemplate}
                                            handleOnChangeReact={handleOnPlanPricingTemplate}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Engagement Template *</label>
                                        <ReactSelectComponent
                                            placeholder='Select...'
                                            options={engagementTemplatesWithValueLabel}
                                            value={engagementTemplate}
                                            handleOnChangeReact={handleOnEngagementTemplate}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Default State</label>
                                        <Controller
                                            control={control}
                                            name="default_state_id"
                                            render={({ onChange, ref, value }) => (
                                                <Select
                                                    isSearchable
                                                    inputRef={ref}
                                                    value={value}
                                                    options={statesWithValueLabel}
                                                    className={(errors.default_state_id) ? 'is-invalid' : ''}
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    onChange={val => {
                                                        onChange(val)
                                                    }}
                                                />)}
                                        />
                                        {(errors.default_state_id) && (<div className='invalid-feedback'>{errors.default_state_id.message}</div>)}
                                    </div>
                                    <InputField
                                        label='Default County'
                                        fieldName='default_county'
                                        register={register}
                                        errors={errors.default_county}
                                        placeholder='Enter Default County'
                                        defaultValue={appUserDetails.default_county}
                                    />
                                    <div>
                                        <div className="custom-control form-group mt-1 custom-switch">
                                            <input type="checkbox" checked={recordingStatus}
                                                ref={register}
                                                className="custom-control-input" name='default_vision_recording_status' id="visionRecordingStatus" onClick={handleVisionRecordingStatus} />
                                            <label className="custom-control-label" htmlFor='visionRecordingStatus'>Enable Vision Recording</label>
                                            {errors.default_vision_recording_status ? (<div className='invalid-feedback'>{errors.default_vision_recording_status.message}</div>) : <small className='form-text text-muted switch-help-text'></small>}
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(UpdateAppUserPage)
