import { action, observable } from 'mobx'
import RootStore from './Root'
import { getOrganizations, addUpdateOrganization, getSingleOrganizationDetails } from '../api/OrganizationAction'
import { initialTableDataWithPaginationDetails, initialOrganizationInformation } from './InitialValues'
import { Dictionary, OrganizationInformation } from './types.d'

interface OrganizationData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<OrganizationInformation>
}

class OrganizationStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public organizationDetails: OrganizationInformation
    @observable public organizationData: OrganizationData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean
    @observable public orgName = ''

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.organizationDetails = initialOrganizationInformation
        this.organizationData = initialTableDataWithPaginationDetails
    }

    //get the list of Organizations with details
    fetchOrganizations = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getOrganizations(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.organizationData = response.data.data
            this.organizationDetails = initialOrganizationInformation
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the organization details in variable for show in update organization page
    setOrganizationDetails = (orgId: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleOrganizationDetails(orgId).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.organizationDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //add/update details of given id
    addUpdateOrganizationDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateOrganization(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchOrganizations(this.page, this.searchItem)
                resolve(response.data)

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    @action resetOrganizationDetails = () => {
        this.organizationDetails = initialOrganizationInformation
    }

    @action resetError = () => {
        this.isApiError = false
        this.apiErrorMessage = ''
    }

    @action
    setOrgName(): void {
        const orgId = localStorage.getItem('userOrg') || ''
        console.log('con22', this.rootStore.allOrgStore.orgList)
        const orgName = this.rootStore.allOrgStore.findOrg(orgId)
        console.log('con4', orgId, orgName)
        if (orgName !== undefined) {
            console.log('orgName.name', orgName.name)
            this.orgName = orgName.name
        }
    }
  
}

export default OrganizationStore
