import { observable } from 'mobx'
import RootStore from '../Root'
import { addUpdateProblemQuestion, getProblemQuestions, getSingleProblemQuestionDetails } from '../../api/vision/ProblemQuestionActions'
import { ProblemQuestionInformation, Dictionary } from '../types.d'
import { initialTableDataWithPaginationDetails, initialSingleProblemQuestion } from '../InitialValues'
import OptionInfoStore from './OptionInfo'
// import DescriptionInfoStore from './DescriptionInfo'

interface ProblemQuestionData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<ProblemQuestionInformation>
}

class ProblemQuestionStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public problemQuestionDetails: ProblemQuestionInformation
    @observable public problemQuestionData: ProblemQuestionData
    @observable public searchItem: string
    @observable public page: number
    @observable public problemQuestionDataHistory: Array<ProblemQuestionData>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.problemQuestionDetails = initialSingleProblemQuestion
        this.problemQuestionDataHistory = []
        this.problemQuestionData = initialTableDataWithPaginationDetails
    }

    resetProblemQuestionDetails = (): void => {
        this.problemQuestionDetails = {
            _id: '',
            title: '',
            sub_title: '',
            options: [],
            display_order: 1
        }
    } 

    // //get the list of problem questions with details
    fetchProblemQuestions = (page: number, searchItem: string, action: string): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.problemQuestionDataHistory.length; i++) {
                if (this.problemQuestionDataHistory[i].currentPage === page) {
                    this.problemQuestionDataHistory.push(this.problemQuestionData)
                    this.problemQuestionData = this.problemQuestionDataHistory[i]
                    return
                }
            }
            this.problemQuestionDataHistory.push(this.problemQuestionData)

        } else if (action === 'search_item') {
            this.problemQuestionDataHistory = []
            this.searchItem = searchItem
            this.page = 1

        } else if(action === 'update' || action === 'add') {
            this.problemQuestionDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getProblemQuestions(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.problemQuestionData = response.data.data
            this.problemQuestionDetails = initialSingleProblemQuestion
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the problemQuestion details in problemQuestionDetails variable for show in update problemQuestion page
    setProblemQuestionDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleProblemQuestionDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.problemQuestionDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the problemQuestion details of given problemQuestion_id
    addUpdateSingleProblemQuestion = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateProblemQuestion(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchProblemQuestions(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    addNewOptionInfo(label: string, type: string, placeholder: string, display_order: number, plan: Array<string>, is_active: boolean): void {
        const data = {
            label: label,
            type: type,
            placeholder: placeholder,
            display_order: display_order,
            plan: plan,
            is_active: is_active
        }
        const optionInfo = new OptionInfoStore(data)
        this.problemQuestionDetails.options.push(optionInfo)
    }

    deleteOptionInfo(descriptionInfo: OptionInfoStore): void {
        this.problemQuestionDetails.options = this.problemQuestionDetails.options.filter((item) => item !== descriptionInfo)
    }
}

export default ProblemQuestionStore
