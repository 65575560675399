import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { TableCellActiveInactive } from '../table/TableCellActiveInactive'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import _ from 'lodash'
import { debounceSearch } from '../../utils/debounce'
import { ShowTags } from '../commonComponent/commonFuction'
import { USER_ROLE_DEED_ONLY, USER_ROLE_ORG_ADMIN, USER_ROLE_SUPER_ADMIN } from '../../utils/constant'
interface Props {
    rootStore: RootStore,
}

const TableCellTitleCase: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {value === 'EMPLOYEE' ? 'Team-iPad' : value === 'ADMIN' ? 'Admin-iPad' : value === 'ADMIN_CMS' ? 'Team-CMS': value === 'TEAM_CMS_IPAD' ? 'Team-iPad/CMS': value === 'ADMIN_CMS_TEAM_IPAD' ? 'Admin-CMS/Team-iPad': value  == USER_ROLE_DEED_ONLY ? 'Deed Only' : value  == USER_ROLE_ORG_ADMIN ? 'Organization Admin' : ''}
        </>
    )
}

const AppUsersPage: React.FC<Props> = ({ rootStore }) => {
    const { appUserStore, authStore, setTitle } = rootStore
    const { isAdmin, userOrg } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, appUserData, searchByRoleFilter, fetchAppUser, isPageOpened, excludeTestData, setSearchItemField, resetFilter } = appUserStore
    const all = { value: 'ALL', label: 'All' }
    const roleValueLabel = [
        all, {
            value: 'ADMIN',
            label: 'Admin-iPad'
        }, {
            value: 'ADMIN_CMS',
            label: 'Team-CMS'
        }, {
            value: 'EMPLOYEE',
            label: 'Team-iPad'
        }, {
            value: 'TEAM_CMS_IPAD',
            label: 'Team-iPad/CMS'
        }, {
            value: 'ADMIN_CMS_TEAM_IPAD',
            label: 'Admin-CMS/Team-ipad'
        }, {
            value: USER_ROLE_DEED_ONLY,
            label: 'Deed Only'
        }, {
            value: USER_ROLE_ORG_ADMIN,
            label: 'Organization Admin'
        }
    ]
    const currentUserRole = localStorage.getItem('userRole') || ''

    const loadPage = (page: number) => {
        fetchAppUser(page, searchItem, searchByRoleFilter, excludeTestData)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        debounceSearch(() => fetchAppUser(1, e.target.value, searchByRoleFilter, excludeTestData))
    }

    const handleOnChangeRole = (data: any) => {
        if (data !== null) {
            fetchAppUser(1, '', data, excludeTestData)
        }
    }

    const handleExcludeTestData = (e: any) => {
        const value = e.target.checked
        fetchAppUser(1, searchItem, searchByRoleFilter, value)
    }

    useEffect(() => {
        setTitle('Users | OathZephyr')        
        if (isAdmin && !isPageOpened) {
            resetFilter()
            fetchAppUser(1, '', { value: 'ALL', label: 'All' }, true)
        }
    } , [isAdmin, userOrg])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Users
                    {currentUserRole === USER_ROLE_SUPER_ADMIN && <Link to='/app-users/add/org-admin' className='ml-auto px-3'>Add Organization Admin</Link>}
                    <Link to='/app-users/add' className={ currentUserRole === USER_ROLE_ORG_ADMIN ? 'ml-auto px-3' : 'px-3'}>Add User</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <label className='col-md-auto mt-2 pr-0'>Role:</label>
                            <div className='col-md-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={roleValueLabel}
                                    value={searchByRoleFilter}
                                    handleOnChangeReact={handleOnChangeRole}
                                />
                            </div>

                            <div className='col-md-auto  my-2 ml-md-auto mx-4 pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestData" onClick={handleExcludeTestData} checked={excludeTestData} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestData'>Exclude Test Users</label>
                            </div>
                            <div className='col-md-2 d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItem} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>

                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'first_name', title: 'First Name' },
                                    { name: 'last_name', title: 'Last Name' },
                                    { name: 'email', title: 'Email' },
                                    { name: 'user_associated_tags', title: 'Tags', component: ShowTags },
                                    { name: 'account_code', title: 'Account Code' },
                                    { name: 'attorney_code', title: 'Attorney Code' },
                                    { name: 'role', title: 'Role', component: TableCellTitleCase },
                                    { name: 'status', title: 'Active', component: TableCellActiveInactive },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={appUserData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={appUserData.currentPage}
                            totalPages={appUserData.totalPage}
                            totalItems={appUserData.totalItem}
                            itemsPerPage={appUserData.itemPerPage}
                            isFiltered={appUserData.isFiltered}
                            totalAllItems={appUserData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AppUsersPage)
